(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/content-editable/assets/javascripts/content-editable.js') >= 0) return;  svs.modules.push('/components/lb-ui/content-editable/assets/javascripts/content-editable.js');

'use strict';

const _excluded = ["value", "onChange", "className", "maxLength", "onEnter", "disabled"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  detect
} = svs.core;
const KEY_CODE_ENTER = 13;

function pasteHtmlAtCaret(html) {
  let sel, range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      const el = document.createElement('div');
      el.innerHTML = html;
      const frag = document.createDocumentFragment();
      let node = el.firstChild;
      let lastNode;
      while (node) {
        lastNode = frag.appendChild(node);
        node = el.firstChild;
      }
      range.insertNode(frag);
      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }
}
class ContentEditable extends React.Component {
  constructor(props) {
    super(props);
    _defineProperty(this, "ref", React.createRef());
    _defineProperty(this, "handleChange", event => {
      const eventValue = event.target.innerText.slice(0, this.props.maxLength);
      if (this.lastHTML !== eventValue) {
        this.props.onChange(eventValue);
      }
      this.lastHTML = eventValue;
    });
    _defineProperty(this, "handleKeyDown", () => {
      if (this.props.disabled) {
        return;
      }
      if (detect.browser.ie()) {
        setTimeout(() => {
          const value = this.ref.current.innerText;
          this.props.onChange(value);
        });
      }
    });
    _defineProperty(this, "handleKeyPress", event => {
      const pressingOptionalKey = !!event.shiftKey;
      const hitEnter = !!~[event.keyCode, event.which].indexOf(KEY_CODE_ENTER);
      const numberOfSelectedCharacters = window.getSelection().toString().length;
      if (hitEnter && !pressingOptionalKey) {
        event.preventDefault();
        this.props.onEnter();
      } else if (event.target.innerText.length - numberOfSelectedCharacters >= this.props.maxLength || this.props.disabled) {
        event.preventDefault();
      }
    });
    _defineProperty(this, "handlePaste", event => {
      event.preventDefault();
      if (this.props.disabled) {
        return;
      }
      const originalText = event.currentTarget.innerText;
      let clipBoardText;
      if (window.clipboardData) {
        clipBoardText = window.clipboardData.getData('text');
      } else if (event.clipboardData) {
        clipBoardText = event.clipboardData.getData('text/plain');
      }
      const numberOfSelectedCharacters = window.getSelection().toString().length;
      const pasteText = clipBoardText.slice(0, this.props.maxLength - originalText.length + numberOfSelectedCharacters);
      pasteHtmlAtCaret(pasteText);
      this.props.onChange(event.currentTarget.innerText);
    });
    this.lastHTML = props.value;
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.disabled !== this.props.disabled) return true;
    if (nextProps.value !== this.ref.current.innerText) return true;
    return false;
  }
  componentDidUpdate() {
    if (this.props.value !== this.ref.current.innerText) {
      this.ref.current.innerText = this.lastHTML = this.props.value.slice(0, this.props.maxLength);
    }
  }
  render() {
    const _this$props = this.props,
      {
        value,
        onChange,
        className,
        maxLength,
        onEnter,
        disabled
      } = _this$props,
      props = _objectWithoutProperties(_this$props, _excluded);
    const classNames = ['content-editable'];
    className && classNames.push(className);
    return React.createElement("div", _extends({
      className: classNames.join(' '),
      contentEditable: !disabled
      ,
      dangerouslySetInnerHTML: {
        __html: value.slice(0, maxLength)
      },
      onBlur: this.handleChange,
      onInput: this.handleChange,
      onKeyDown: this.handleKeyDown,
      onKeyPress: this.handleKeyPress,
      onPaste: this.handlePaste,
      ref: this.ref,
      role: "textbox",
      tabIndex: 0
    }, props));
  }
}
_defineProperty(ContentEditable, "defaultProps", {
  maxLength: Infinity,
  onEnter: () => {},
  value: ''
});
setGlobal('svs.components.lbUi.ContentEditable.ContentEditable', ContentEditable);

 })(window);